import Button from '@/components/atoms/Button'
import CircleAnimation from '@/components/atoms/CircleAnimation'
import Container from '@/components/atoms/Container'
import {ImageType} from '@/components/types/image'

import {LinkType} from '@/components/types/link'
import {md} from '@/config'
import useMediaQuery from '@/functions/useMediaQuery'
import Image from 'next/image'
import Link from 'next/link'
import styles from './Services.module.scss'

type ServicesProps = {
  block: {
    image: ImageType
    serviceMain: {
      title: LinkType
      text: string
    }
    serviceSub: [
      {
        title: LinkType
        text: string
      }
    ]
  }
}

export default function Services({block}: ServicesProps) {
  const {image, serviceMain, serviceSub} = block
  const isMd = useMediaQuery(md)
  return (
    <>
      <Container removePaddingMobile>
        <div className={styles.serviceMainImageWrapper}>
          {serviceMain?.title?.url && (
            <Link href={serviceMain.title.url} passHref>
              <a className={styles.serviceMainWrapper}>
                <div className={styles.serviceMainContainer}>
                  <Button
                    attributes={{'data-aos': 'fade-up'}}
                    tag="div"
                    type="blue"
                    text={serviceMain?.title?.title}
                    className={styles.title}
                    icon={isMd ? null : 'arrowRightOutline'}
                  />
                  <div data-aos="fade-up" className={styles.text}>
                    {serviceMain?.text}
                  </div>
                </div>
              </a>
            </Link>
          )}
          {image?.mediaItemUrl && (
            <div className={styles.imageWrapper}>
              <Image
                src={image.mediaItemUrl}
                alt={image.altText}
                width={image.mediaDetails.width}
                height={image.mediaDetails.height}
                layout="responsive"
                objectFit="cover"
                data-aos="fade-in"
              />
            </div>
          )}
        </div>
      </Container>
      <Container removePaddingMobile>
        <div className={styles.servicesSubWrapper}>
          {serviceSub?.length > 0 &&
            serviceSub.map(
              (service, index) =>
                service.title.url && (
                  <Link key={index} passHref href={service.title.url}>
                    <a className={styles.serviceSubWrapper}>
                      <div className={styles.serviceSubContainer}>
                        <Button
                          attributes={{'data-aos': 'fade-up'}}
                          tag="div"
                          type="blue"
                          text={service?.title?.title}
                          className={styles.title}
                          icon={isMd ? null : 'arrowRightOutline'}
                        />
                        <div data-aos="fade-up" className={styles.text}>
                          {service?.text}
                        </div>
                      </div>
                    </a>
                  </Link>
                )
            )}

          <CircleAnimation />
        </div>
      </Container>
    </>
  )
}
